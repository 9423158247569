import { useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getDataPromise, postDataPromise } from 'brock-fetch-utils';
import {
    getPassengerIdentificationUri,
    getPassengersUri,
    getSettingsUri,
    validateURLsUri,
} from '../constants/apiConstants';

export function buildSettingsQuery() {
    return {
        queryKey: ['settings'],
        queryFn: () => getDataPromise(getSettingsUri()),
        enabled: true,
        staleTime: Infinity,
    };
}

export function useSettings() {
    const { data: settings, ...payload } = useQuery(buildSettingsQuery());

    return { ...settings, ...payload };
}

export function fetchPassengers(id, bagTagNumber, surname, searchDate) {
    return postDataPromise(getPassengersUri(), {
        encryptedRequest: id,
        bagTagNumber: bagTagNumber,
        searchDate: searchDate,
    });
}

export function validateURLs(helpCenterURL, returnToHomepageURL) {
    return postDataPromise(
        validateURLsUri({ helpCenterURL, returnToHomepageURL })
    );
}

export function buildPassengersQuery(
    isEncryptedRequestRequired,
    isPnrOnlySearchEnabled,
    isFirstNameRequired,
    parameters
) {
    const keys = [];
    const {
        searchToken,
        pnr,
        firstName,
        surname,
        helpCenterURL,
        nav,
        returnToURL,
        bagTagNumber,
        searchDate,
    } = parameters;
    if (isEncryptedRequestRequired) {
        keys.push(searchToken);
    } else if (isPnrOnlySearchEnabled) {
        keys.push(pnr);
    } else if (isFirstNameRequired) {
        keys.push(pnr, firstName, surname, helpCenterURL, nav, returnToURL);
    } else {
        keys.push(pnr, surname, helpCenterURL, nav, returnToURL);
    }

    const keysPresent = keys.every((key) => !!key);

    return {
        queryKey: ['passengers', ...keys],
        queryFn: () =>
            fetchPassengers(searchToken, bagTagNumber, surname, searchDate),
        enabled: keysPresent,
        initialData: [],
    };
}

export function usePassengers(bagTagNumber, searchDate) {
    const [searchParams] = useSearchParams();
    const {
        isEncryptedRequestRequired,
        isPnrOnlySearchEnabled,
        isFirstNameRequired,
    } = useSettings();

    const searchToken = searchParams.get('searchToken');
    const pnr = searchParams.get('pnr');
    const firstName = searchParams.get('firstName');
    const surname = searchParams.get('surname');
    const helpCenterURL = searchParams.get('helpCenterURL');
    const nav = searchParams.get('nav');
    const returnToURL = searchParams.get('returnToURL');

    return useQuery(
        buildPassengersQuery(
            isEncryptedRequestRequired,
            isPnrOnlySearchEnabled,
            isFirstNameRequired,
            {
                searchToken,
                pnr,
                firstName,
                surname,
                helpCenterURL,
                nav,
                returnToURL,
                bagTagNumber, 
                searchDate
            }
        )
    );
}

export function buildIdentificationQuery(
    pnr,
    firstName,
    surname,
    onSuccess
) {
    return {
        queryKey: ['identification', pnr, firstName, surname],
        queryFn: () =>
            getDataPromise(
                getPassengerIdentificationUri({ pnr, firstName, surname })
            ),
        enabled: false,
        onSuccess,
    };
}
