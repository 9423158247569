import airlineConfiguration from '../customer/airlineConfiguration.json';

export function getSettingsUri() {
    return `${airlineConfiguration.smartNotifyApiEndpoint}/api/MicrositeSettings/GetMicrositeSettings`;
}

export function getPassengerIdentificationUri({ pnr, firstName, surname }) {
    return `${airlineConfiguration.smartNotifyApiEndpoint}/api/Events/GetEncryptedString?firstName=${firstName}&surname=${surname}&pnr=${pnr}`;
}

export function getPassengersUri() {
    return `${airlineConfiguration.smartNotifyApiEndpoint}/api/Events/GetPassengerInformation`;
}

export function submitClaimUri() {
    return `${airlineConfiguration.smartNotifyApiEndpoint}/api/CreateTracerRecord/CreateTracerRecord`;
}

export function saveNotificationSettingsUri() {
    return `${airlineConfiguration.smartNotifyApiEndpoint}/api/PassengerNotification/SavePassengerNotificationMethods`;
}

export function validateURLsUri({ helpCenterURL, returnToHomepageURL}) {
    return `${airlineConfiguration.smartNotifyApiEndpoint}/api/Security/ValidateUrls?helpCenterURL=${helpCenterURL}&returnToHomepageURL=${returnToHomepageURL}`;
}
