const airlineCodes = {
    aeromexico: 'AM',
    airCanada: 'AC',
    indiGo: '6E',
    jetBlue: 'B6',
    latam: 'LA',
    southwestAirlines: 'WN',
    virginAustralia: 'VA',
};

export default airlineCodes;
